<template>
<div class="fundLog page">
  <headers title="资金日志"></headers>
  <div class="scroll">
      <ul class="list">
        <li class="flexSpaceBetween" v-for="(item,index) in list" :key="index">
          <div class="left">
            <div class="title">{{item.info}}</div>
            <div class="time">{{item.add_time}}</div>
          </div>
          <div class="price">{{item.jc_class}}{{item.num}}</div>
        </li>
      </ul>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "fundLog",
  components: {headers},
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      $api.bill({
        token: this.$store.getters['getToken']
      }).then(res => {
        this.list = res.data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.fundLog {
  .list {
    padding: 0 16px;
    li {
      border-bottom: 1px solid #f0f0f0;
      padding: 15px 0;
      .title {
        font-size: 15px;
        text-align: left;
      }
      .time {
        color: #999;
        text-align: left;
        margin-top: 15px;
      }
      .price {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
</style>